import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const sidebarSelectSlice = createSlice({
  name: "sidebarSelect",
  initialState: {
      data : ''
  },
  reducers: {
    setSidebarSelect(state, action: PayloadAction<any>) {
      state.data = action.payload;
    }
  }
})

export const { setSidebarSelect } = sidebarSelectSlice.actions
export default sidebarSelectSlice.reducer