import React, { useState, CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import {
  CheckCircleOutlined 
} from '@ant-design/icons';


const { Content } = Layout;

const Price: React.FC = () => {
  const navigate = useNavigate();
  const [activePlan, setActivePlan] = useState("d30");

  const handleClick = (event: any, days: any) => {
    setActivePlan(days);
  };

  const priceData: any = {
    d30 : { finalPrice: "5.99", originPrice: "19.96", discountPrice: "5.99" },
    d90 : { finalPrice: "16.99", originPrice: "56.63", discountPrice: "5.66" },
    d180 : { finalPrice: "31.99", originPrice: "106.63", discountPrice: "5.33" },
    d365 : { finalPrice: "61.99", originPrice: "206.63", discountPrice: "5.16" },
  }

  const jumpToPayment = () => {
    navigate(`/payment?activePlan=${activePlan}`);
  }

  return (
    <div className="body-price" style={{ background: "#05010d", width: "100%", height: "100%" }}>
      <section className="tc-wrapper">
        <div className="container">
          <div className="section-title">
            <h2 style={{ color: "white", }}>价格</h2>
            <p style={{ color: "hsla(0,0%,100%,.6)" }}>我们为所有用户提供免费计划，为想要解锁更多功能的用户提供高级计划。</p>
            <div className="plan-tabs">
              <div className={`plan-tab ${activePlan === 'd30' ? 'active' : ''}`} onClick={(e) => handleClick(e, "d30")} xt-marked="ok">30天会员</div>
              <div className={`plan-tab ${activePlan === 'd90' ? 'active' : ''}`} onClick={(e) => handleClick(e, "d90")} xt-marked="ok">90天会员</div>
              <div className={`plan-tab ${activePlan === 'd180' ? 'active' : ''}`} onClick={(e) => handleClick(e, "d180")} xt-marked="ok">180天会员</div>
              <div className={`plan-tab ${activePlan === 'd365' ? 'active' : ''}`} onClick={(e) => handleClick(e, "d365")} xt-marked="ok">365天会员</div>
            </div>
          </div>
        </div>
      </section>
      <section className="tc-wrapper">
        <div className="container md flex price-container" style={ pricecardsContainer }>
          <div className="price-card normal">
            <div className="tilt">
              <div className="price-content">
                <div className="price-header">
                  <div className="price-name">
                    <span className="name" style={{ color: "hsla(0,0%,100%,.6)", fontWeight: "bold" }}>非会员</span>
                  </div>
                  <h4 className="price-value" >免费</h4>
                </div>
                <div className="price-body">
                  <div className="price-feature-title" style={{ position: 'relative' }}>
                    <span>所有核心功能</span>
                    <div className="dashed"></div>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>Youtube 和 Netflix 双语字幕</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限单词/句子翻译</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限全文翻译</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>openAI 单词释义</span>
                  </div>
                </div>
                <div className="price-footer">
                  <div className="tc-btn md solid round">免费使用</div>
                </div>
              </div>
            </div>
          </div>
          <div className="price-card premium">
            <div className="tilt">
              <div className="price-content premium loading">
                <div className="price-header">
                  <div className="price-name">
                    <span className="name premium" xt-marked="ok">Premium</span>
                    <div className="tag" xt-marked="ok"><span>限时3折优惠</span></div>
                  </div>
                  <h4 className="price-value">
                    <div className="final-value" xt-marked="ok"><span>${priceData[activePlan].finalPrice}</span></div>
                    <div className="origin-price" xt-marked="ok"><span>${priceData[activePlan].originPrice}</span></div>
                    <div className="discount-price" xt-marked="ok">
                      <span>$ {priceData[activePlan].discountPrice} </span><span className="unit" xt-marked="ok">/
                        30天
                      </span>
                    </div>
                  </h4>
                </div>
                <div className="price-body">
                  <div className="price-feature-title">
                    <span>核心的一切，加上</span>
                    <div className="dashed"></div>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限划词翻译</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限划句翻译</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限单词收藏</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限句子收藏</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>无限句子训练</span>
                  </div>
                  <div className="price-feature">
                    <CheckCircleOutlined style={checkIconStyle}/>
                    <span>OpenAI 全部释义</span>
                  </div>
                </div>
                <div className="price-footer">
                  <div className="tips"><span>🐧 早鸟价，限时优惠</span></div>
                  <div className="tc-btn md primary round magic " onClick={jumpToPayment}><span>限时优惠购买</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container md agreement-container">
          <p>By clicking “Upgrade now” and purchasing, you agree to our Terms of Service and Privacy Policy. To ensure uninterrupted access after your subscription ends, you agree that your subscription will renew for another term using the payment method provided. You may turn off auto-renewal at any time by logging into your account.</p>
        </div>
      </section>
    </div>
  );
};

const checkIconStyle = {
  marginRight: '10px'
}

const pricecardsContainer: CSSProperties = { 
  display: 'flex', 
  flexDirection: "row", 
  justifyContent: "space-evenly", 
  alignItems: 'baseline'
}

export default Price;