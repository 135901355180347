import React, { useState, CSSProperties } from 'react';
import SidebarMenu from '../components/sidebarMenu';
import { Layout, Button } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';


const { Content } = Layout;


const Login: React.FC = () => {

  const handleLogin = () => {
    window.location.href = "https://didienglish.com/api/googlelogin";
  };

  return (
    <div style ={ overlay }>
      <div style ={ form }>
        <div style ={ con }>
          <div style ={ head_form }>
            <h2 style = { header2Style }>Log In</h2>
            <p style = { header2P }>login here for using Didi English</p>
          </div>
          <Button 
            onClick={handleLogin} 
            size="large" 
            style={{
              backgroundColor: "#4285F4", 
              color: "white",
              fontWeight: '500',
              border: 'none',
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '60px',
              width: '300px',
              padding: '5px',
              borderRadius: '4px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)'
            }}
          >
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" 
              alt="google-icon" 
              style={{ paddingLeft: '20px', paddingRight: '20px', width: '70px', height: '100%', background: 'white', borderRadius: '4px' }} 
            />
            <div style={{ textAlign: 'center', width: '100%', fontSize: '18px' }}>
              Sign in with Google
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;

const overlay = {
  padding: '50px', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  paddingLeft: '200px',
  backgroundImage: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  opacity: ".95",
  height: '100%'
}

const form = {
  width: '450px',
  minHeight: '500px',
  height: 'auto',
  borderRadius: '5px',
  margin: '2% auto',
  boxShadow: '0 9px 50px hsla(20, 67%, 75%, 0.31)',
  padding: '2%',
  backgroundImage: 'linear-gradient(-225deg, #E3FDF5 50%, #FFE6FA 50%)'
}

const con: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-around',
  WebkitJustifyContent: 'space-around',
  flexWrap: 'wrap',
  WebkitFlexWrap: 'wrap',
  margin: '0 auto'
}

const head_form: CSSProperties = {
  margin: '2% auto 10% auto',
  textAlign: 'center',
}

const header2Style = {
  fontSize: '250%',
  fontFamily: "'Playfair Display', serif",
  color: '#3e403f',
}

const header2P = {
  letterSpacing: "0.05em"
}
