import React, { useState } from 'react';
import SidebarMenu from '../components/sidebarMenu';
import { Layout } from 'antd';


const { Content } = Layout;


const Explore: React.FC = () => {

  return (
    <div style ={ overlay }>
      <div>
        <span style={ developing_font }>Coming soon ...</span>
      </div>
    </div>
  );
};

export default Explore;

const overlay = {
  padding: '50px', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  paddingLeft: '200px',
  backgroundImage: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  opacity: ".95",
  height: '100%'
}

const developing_font = {
  fontSize: "x-large",
  fontWeight: "bolder"
}