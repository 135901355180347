import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router-dom';

interface DataType {
    word: string,
    definition: string,
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Phrase / Sentence',
    dataIndex: 'word',
    key: 'word',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Definition',
    dataIndex: 'definition',
    key: 'definition',
  }
];

const Phrase: React.FC = () => {
  const navigate = useNavigate();

  const [savedWords, setSavedWords] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    // if no token jump to login page
    if(!token){
      navigate("/login");
      return;
    }

    axios.get('https://didienglish.com/api/getSavedWords', {
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if(response.data.status === "ok"){
        if(response.data && response.data.r && response.data.r.length > 0){
          let words = response.data.r.filter((item: any, index: any) => {
            item.key = index.toString();
            return item.type === "sentence" 
          })
          setSavedWords(words);
        }
      }
    })
    .catch(error => {
      console.error('Error fetching data', error);
      // Check for "go login" message here
      if(error.response && error.response.data && error.response.data.message === "go login") {
        navigate('/login');
        return;
      }else if(error.response && error.response.data && error.response.data.message === "go payment") {
        navigate('/price');
        return;
      }
    });
  }, []);

  return (
    <div style={{ paddingLeft: '200px' }}>
      <Table columns={columns} dataSource={savedWords} />
    </div>
  );
};

export default Phrase;