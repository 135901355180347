import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import SidebarMenu from './components/sidebarMenu';
import { Layout } from 'antd';
import  Login  from "./pages/Login";
import  Word  from "./pages/Word";
import  Phrase  from "./pages/Phrase";
import  Explore  from "./pages/Explore";
import  Home  from "./pages/Home";
import  Payment  from "./pages/Payment";
import  User  from "./pages/User";
import  Price  from "./pages/Price";
import  Paysuccess  from "./pages/Paysuccess";

const { Content } = Layout;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);

  return (
      <BrowserRouter>
        <Layout style={{ minHeight: '100vh' }}>
          <SidebarMenu collapsed={collapsed} setCollapsed={setCollapsed} />
          <Layout>
            <Content>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="word" element={<Word />} />
                <Route path="phrase" element={<Phrase />} />
                <Route path="login" element={<Login />} />
                <Route path="explore" element={<Explore />} />
                <Route path="payment" element={<Payment />} />
                <Route path="user" element={<User />} />
                <Route path="price" element={<Price />} />
                <Route path="paysuccess" element={<Paysuccess />} />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </BrowserRouter>
  );
};

export default App;