import React, { useState, CSSProperties } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Layout, Breadcrumb } from 'antd';
import {
  LoginOutlined,
  AppstoreOutlined,
  FileWordOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { MenuProps } from 'antd';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  path?: string,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    path,
  } as MenuItem;
}

const SidebarMenu: React.FC<{collapsed: boolean, setCollapsed: React.Dispatch<React.SetStateAction<boolean>>}> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  let hasToken = false;

  const cookies = document.cookie.split(';');
  console.log(cookies);
  const name = 'EnglishLearner'; // Replace with your cookie name
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      if(cookie.substring(name.length + 1)){
        hasToken = true;
      }
    }
  }
  
  const items: MenuItem[] = [
    getItem('Recap', 'recap', <FileWordOutlined />, '/', [
      getItem('Word', 'word', undefined, '/word'),
      getItem('Phrase', 'phrase', undefined, '/phrase'),
    ]),
    getItem('Explore', 'explore', <AppstoreOutlined />, '/explore'),
    ...hasToken ? [getItem('User', 'user', <UserOutlined />, '/user'), getItem('Logout', 'logout', <LogoutOutlined />)] : [getItem('Login', 'login', <LoginOutlined />, '/login')],
  ];

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from local storage
    // Remove the cookie
    document.cookie = 'EnglishLearner=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    navigate("/login");
    window.location.reload(); // refresh page
  };

  const onClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'logout') {
      handleLogout(); // Call the logout function when Logout button is clicked
    } else {
      navigate(`/${e.key as string}`);
    }
  };

  const selectedKeys = [location.pathname.split("/")[1]];
  
  return (
    <Sider className="custom-sider" style={{ position: "fixed", height: "100%", zIndex: '999' }} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div className="demo-logo-vertical" style={ sidebar_logo_div }>
        <img style={ sidebar_logo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1690830893/DIDI-english/tiks6bggrfevyxbnjyub.png" alt="DIdI English" />
        {
          collapsed ? (
            <></>
          )
          :
          (
            <span style={ logo_font }>Didi English</span>
          )
        }
      </div>  
      <Menu theme="dark" selectedKeys={selectedKeys} mode="inline" items={items} onClick={onClick}/>
    </Sider>
  );
};

export default SidebarMenu;

const sidebar_logo_div = { 
  height: "5vh", 
  background: "black",
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(90deg, rgba(0, 240, 255, 0.9) 0%, rgba(35, 149, 255, 0.9) 98.44%)'
}

const sidebar_logo = {
  height: "100%",
  backgroundSize: "cover",
  borderRadius: "5px"
}

const logo_font : CSSProperties = {
  color: 'white',
  fontSize: "large",
  fontWeight: "800",
  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // An example font stack, adjust as needed
  letterSpacing: '1px', // Space out the letters a little
  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)', // A subtle shadow
}

