import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Paysuccess: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // assuming your home route is '/'
  }

  return (
    <div style={{ paddingLeft: '200px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '50px' }}>
        <Result
            status="success"
            title={<span style={{ fontWeight: 'bold' }}>Payment Successful!</span>}
            subTitle="Your payment was processed successfully. Welcome to DIDI ENGLISH."
        />
        </div>
    </div>
  );
};

export default Paysuccess;
