import React, { useState, useEffect, CSSProperties } from 'react';
import { Layout } from 'antd';


const { Content } = Layout;


const Home: React.FC = () => {

  useEffect(() => {
    const cookies = document.cookie.split(';');
    console.log(cookies);
    const name = 'EnglishLearner'; // Replace with your cookie name
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        const cookieValue = cookie.substring(name.length + 1);
        localStorage.setItem('token', cookieValue); // Store the token in local storage
        let event = new CustomEvent("get-msg-video", { detail: { type: 'storeToken', token: cookieValue } });
        window.dispatchEvent(event);
      }
    }
  }, []);

  return (
    <div style ={ overlay }>
      <div style ={ head_form }>
        <h2 style = { header2Style }>Welcome to Didi English</h2>
      </div>
      <div style={ pics_container }>
        <div style={ forms_div }>
          <div>
            <div style ={ form }>
                <img style={ introduceLogo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1691094026/DIDI-english/tba5im3vdmdw1tjwwjjs.png" alt="pic1" />
            </div>
            <div>
              <span style={ describFont }>双语字幕省时又省力</span>
            </div>
          </div>
          <div>
            <div style ={ form }>
                <img style={ introduceLogo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1691094026/DIDI-english/qoqryvwyaglbvluqnflw.png" alt="pic2" />
            </div>
            <div>
              <span style={ describFont }>详细翻译一步到位</span>
            </div>
          </div>
        </div>
        <div style={ forms_div }>
            <div>
              <div style ={ form }>
                <img style={ introduceLogo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1691094026/DIDI-english/acyxcaqrqbqowhqoqf6c.png" alt="pic3" />
              </div>
              <div>
                <span style={ describFont }>词组，句子随划随翻</span>
              </div>
            </div>
            <div>
              <div style ={ form }>
              <img style={ introduceLogo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1691094026/DIDI-english/yireotgfqvp4flznvbrb.png" alt="pic4" />
              </div>
              <div>
                <span style={ describFont }>各种练习，提高英语</span>
              </div>
            </div>
        </div>
        <div style={ forms_div }>
          <div>
            <div style ={ form }>
             <img style={ introduceLogo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1691094026/DIDI-english/smupvqjkl6yyv4wmw2z3.png" alt="pic5" />
            </div>
            <div>
              <span style={ describFont }>Netflix上万美剧</span>
            </div>
            </div>
            <div>
              <div style ={ form }>
          <img style={ introduceLogo } src="https://res.cloudinary.com/dz42u9age/image/upload/v1691094026/DIDI-english/scjuwzaf3j66exoasyyf.png" alt="pic6" />
          </div>
              <div>
                <span style={ describFont }>单词，词组，句子无限制收藏</span>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

const overlay: CSSProperties = {
  padding: '50px', 
  display: 'flex', 
  flexDirection: 'column', 
  paddingLeft: '200px',
  backgroundImage: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  opacity: ".95",
  height: '100%',
}

const pics_container : CSSProperties = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around'
}

const forms_div = {
  display: 'flex',
  justifyContent: 'space-evenly',
  marginBottom: '20px'
}

const form = {
  width: '500px',
  height: '250px',
  borderRadius: '5px',
  boxShadow: '0 9px 50px hsla(20, 67%, 75%, 0.31)',
  marginBottom: "5px"
}

const head_form: CSSProperties = {
  textAlign: 'center',
}

const header2Style = {
  fontSize: '250%',
  fontFamily: "'Playfair Display', serif",
  color: '#3e403f',
}

const introduceLogo: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}

const describFont: CSSProperties = {
  fontFamily: "'Playfair Display', serif"
}


