import React, { useState, useEffect, CSSProperties  } from 'react';
import axios from 'axios'; 
import { Upload, Avatar, message, Input, Button, Typography } from 'antd';
import { UserOutlined, EditOutlined, CheckOutlined  } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';

const { Content } = Layout;
const { Text, Title } = Typography;

const EditableField = ({initialValue, title, onSave} : any) => {
  const [isEditing, setIsEditing] = useState(false);
  const [labelValue, setLabelValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setLabelValue(initialValue);
    setInputValue(initialValue);
  }, [initialValue]);

  const handleEditClick = () => {
    if (isEditing) {
      setLabelValue(inputValue);
      onSave(inputValue);  // Call the save function with the new value
    } else {
      setInputValue(labelValue);
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <div>
        <Title level={5} style={titleStyle}>{title}</Title>
      </div>
      <div style={containerStyle}>
        {isEditing ? (
          <Input
            value={inputValue}
            onChange={handleInputChange}
            autoFocus
            style={inputAndTextLabelStyle}
          />
        ) : (
          <Text style={inputAndTextLabelStyle}>
            {labelValue}
          </Text>
        )}
        <Button 
          type="text" 
          icon={isEditing ? <CheckOutlined /> : <EditOutlined />} 
          onClick={handleEditClick} 
        />
      </div>
    </>
  );
};

const User: React.FC = () => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    userName: "",
    email: "",
    photo: ""
  });

  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');
    // if no token jump to login page
    if(!token){
      navigate("/login");
      return;
    }

    axios.get('https://didienglish.com/api/getUserInfo', {
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => {
      if(response.data.status === "ok"){
        setUserInfo({
          userName: response.data.r?.userName || "",
          email: response.data.r?.email || "",
          photo: response.data.r?.photo || ""
        })
      }
    })
    .catch(error => {
      console.error('Error fetching data', error);
      // Check for "go login" message here
      if(error.response && error.response.data && error.response.data.message === "go login") {
        navigate('/login');
        return;
      }
    });
  }, []); 

  const saveUserInfo = async (newUserInfo: any) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post('https://didienglish.com/api/saveUserInfo', newUserInfo, {
        headers: { 
          'Authorization': `Bearer ${token}`
        }
      });
    } catch (error) {
      console.error('Error saving user info', error);
    }
  };
  
  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'done') {
      setAvatar(URL.createObjectURL(info.file.originFileObj));
    }
  };

  const handleUserNameChange = (newUserName: any) => {
    if (newUserName !== userInfo.userName) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        userName: newUserName,
      }));
      let newUserInfo = { ...userInfo, userName: newUserName }
      saveUserInfo(newUserInfo);
    }
  };

  const handleEmailChange = (newEmail: any) => {
    if (newEmail !== userInfo.email) {
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        email: newEmail,
      }));
      let newUserInfo = { ...userInfo, email: newEmail }
      saveUserInfo(newUserInfo);
    }
  };

  return (
    <div style ={ overlay }>
      <div style ={ form }>
        <div style ={ con }>
          <div style ={ head_form }>
            <h2 style = { header2Style }>User Info</h2>
          </div>
          <div style={ uploadImgDiv }>
            <Upload
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {avatar ? (
                <img src={avatar} alt="avatar" style={{ width: '100%' }} />
              ) : (
                <div>
                  <UserOutlined style={{ fontSize: '32px' }} />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </div>
          <div>
            <EditableField initialValue={userInfo.userName} title="user name" onSave={handleUserNameChange} />
            <EditableField initialValue={userInfo.email} title="email" onSave={handleEmailChange}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  backgroundColor: 'white',  // Aesthetic background color
  padding: '10px',  // Adding some padding for better UI
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'  // Simple box shadow for elevated effect
};

const inputAndTextLabelStyle: CSSProperties = {
  width: '300px', // Set your desired width here
  marginRight: '10px',
  fontSize: '1.5em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const titleStyle = {
  width: '350px',
  marginBottom: '10px', 
  color: '#333', 
};

const uploadImgDiv: CSSProperties = {
  textAlign: 'center',
}

const overlay = {
  padding: '50px', 
  display: 'flex', 
  justifyContent: 'center', 
  alignItems: 'center', 
  paddingLeft: '200px',
  backgroundImage: "linear-gradient(to top, #a8edea 0%, #fed6e3 100%)",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  opacity: ".95",
  height: '100%'
}

const form = {
  width: '450px',
  minHeight: '500px',
  height: 'auto',
  borderRadius: '5px',
  margin: '2% auto',
  boxShadow: '0 9px 50px hsla(20, 67%, 75%, 0.31)',
  padding: '2%',
  backgroundImage: 'linear-gradient(-225deg, #E3FDF5 50%, #FFE6FA 50%)'
}

const con: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  WebkitJustifyContent: 'space-around',
  flexWrap: 'wrap',
  WebkitFlexWrap: 'wrap',
  margin: '0 auto'
}

const head_form: CSSProperties = {
  margin: '2% auto 10% auto',
  textAlign: 'center',
}

const header2Style = {
  fontSize: '250%',
  fontFamily: "'Playfair Display', serif",
  color: '#3e403f',
}

const header2P = {
  letterSpacing: "0.05em"
}