import React, { useState, useEffect, CSSProperties } from 'react';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { Divider } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

type PlanKey = 'd30' | 'd90' | 'd180' | 'd365';

const imgSrcs: Record<PlanKey, string> = {
  d30: "https://res.cloudinary.com/dz42u9age/image/upload/v1691163718/DIDI-english/eursj1htzfahaowg6kkd.png",
  d90: "https://res.cloudinary.com/dz42u9age/image/upload/v1691163718/DIDI-english/wbnggkpz3szylh36ieje.png",
  d180: "https://res.cloudinary.com/dz42u9age/image/upload/v1691163718/DIDI-english/uztmwgvailwxuvq2nste.png",
  d365: "https://res.cloudinary.com/dz42u9age/image/upload/v1691163718/DIDI-english/eltkgzpqedx9jpzi2zei.png"
}

const planPrices: Record<PlanKey, string> = {
  d30: "5.99",
  d90: "16.99",
  d180: "31.99",
  d365: "61.99"
};


const Payment: React.FC = () => {
  const navigate = useNavigate();
  const [activePlan, setActivePlan] = useState<PlanKey | "">("");
  const token = localStorage.getItem('token');

  useEffect(() => {
    // if no token jump to login page
    if(!token){
      navigate("/login");
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    const plan = searchParams.get('activePlan') as PlanKey;

    if (plan in planPrices) {
      setActivePlan(plan);
    } else {
      setActivePlan("");
    }
  }, []);

  const FUNDING_SOURCES = [
    FUNDING.PAYPAL,
    FUNDING.CARD
  ];

  const initialOptions = {
    "clientId": "AQXE-9dZAh3eblCD4fbLBoiNn9m9JQ6VL5fiSxszW83K_Oay3bCESnCUxvkE2XjZqZoC97SB4J8vsCjW",
    "currency": "USD",
    "intent": "capture"
  }

  return (
    <div className="Payment-body" style={paymentBody}>
        <div style={ leftSide }>
          <div style={ cartTitleDiv }>
            <span style={ cartTitle }>
              Shopping cart
            </span>
          </div>
          <div style={goBackButtonDiv}>
            <a href="https://www.didienglish.com/price" style={goBackButton}>
              &larr; Back to price page
            </a>
          </div>

          <div style={ priceContainer }>
            <div style={ priceLogoDiv }>
              <img style={ priceLogo } src={activePlan && `${imgSrcs[activePlan]}`} alt="pic1" />
            </div>
            <div style={ itemContainer }>
              <div style={ numberItemDiv }>
                1 item
              </div>
              <div style={ descItemDiv }>
                Didi English Premium Membership
              </div>
            </div>
          </div>
          <Divider />
          <div style={ totalPriceDiv }>
            <span>
              TOTAL
            </span>
            <span>
              {activePlan && `$${planPrices[activePlan]}`}
            </span>
          </div>
        </div>
        <div style={ rightSide }>
          <div style={ cartTitleDiv }>
            <span style={ cartTitle }>
              Checkout
            </span>
          </div>
          <div style={ paymentInfoDiv }>
            <PayPalScriptProvider options={initialOptions}>
            {
              FUNDING_SOURCES.map(fundingSource=>{
                return(
                  <div style={{width: '400px'}} key={fundingSource}>
                    <PayPalButtons
                      fundingSource={fundingSource}
                      
                      style={{
                        layout: 'vertical',
                        shape: 'rect',
                      }}

                      createOrder={async (data, actions) => {
                        if(activePlan != "d30" && activePlan != "d90" && activePlan != "d180" && activePlan != "d365"){
                          alert("Choose a plan!!!")
                        }
                        // Order is created on the server and the order id is returned
                        return fetch("https://didienglish.com/api/create-paypal-order", {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${token}`
                          },
                          // use the "body" param to optionally pass additional order information
                          // like product skus and quantities
                          body: JSON.stringify({
                            product: {
                              activePlan: activePlan
                            }
                          }),
                        })
                        .then((response) => response.json())
                        .then((order) => order.id);
                      }}

                      
                    
                      onApprove={async (data, actions) => {
                        // Order is captured on the server and the response is returned to the browser
                        return fetch("https://didienglish.com/api/capture-paypal-order", {
                        method: "POST",
                          headers: {
                          "Content-Type": "application/json",
                          'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                          orderID: data.orderID
                        })
                      })
                      .then(async (response) => { 
                          let result = await response.json();
                          if(result.status === 'success'){
                            navigate("/paysuccess");
                          }
                        });
                      }}
                    />
                  </div>
                )
              })
            }
            </PayPalScriptProvider>
          </div>
        </div>
    </div>
  );
};

export default Payment;

const leftSide: CSSProperties = {
  width: "30%",
  padding: "10px"
}

const cartTitleDiv: CSSProperties = {
  marginBottom: "7px",
}

const cartTitle: CSSProperties = {
  fontSize: "1.7em",
  fontWeight: "700"
}

const totalPriceDiv: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '1.3em',
  fontWeight: '700'
}

const goBackButtonDiv: CSSProperties = {
  marginBottom: "50px",
}

const goBackButton: CSSProperties = {
  color: "grey",
}

const rightSide: CSSProperties = {
  width: "70%",
}

const paymentBody: CSSProperties = {
  width: '100%',
  height: "100%",
  padding: "50px 50px 50px 250px",
  display: 'flex',
  flexDirection: 'row'
}

const priceLogoDiv: CSSProperties = {
  height: "100px",
  width: "100px"
}

const priceContainer: CSSProperties = {
  display: "flex",
}

const priceLogo: CSSProperties = {
  width: "100%",
  height: "100%",
  objectFit: "cover"
}

const itemContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly"
}

const numberItemDiv: CSSProperties = {
  fontWeight: "700",
  fontSize: "1.1em"
}

const descItemDiv: CSSProperties = {
  color: "#007bff",
  fontWeight: "500"
}

const paymentInfoDiv: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
}
