import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit"

export const fetchLogin = createAsyncThunk('login/fetchLogin', async () => {
    const response = await fetch(`https://didienglish.com/api/googlelogin`);
    const data = await response.json();
    return data;
});
  
const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: {
      data : 'loggedIn',
      isLoading: false,
      error: null,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchLogin.rejected, (state: any, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
})

export const {  } = userInfoSlice.actions
export default userInfoSlice.reducer